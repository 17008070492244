/*** IMPORTS FROM imports-loader ***/
var define = false;

import Plyr from 'plyr';
import gsap from 'gsap';

class Video {
    
    /*
    |
    | Constructor
    |--------------
    */
    constructor($wrapper, params = {}) {
        this.wrapper    = $wrapper;
        this.defaults   = this.initDefaults();
        this.params     = this.initParams(params);

        this.wrapperPlayer      = false;
        this.player             = false;
        this.timelineVideo      = gsap.timeline({ 
                                    paused: true
                                });
        
        this.init();
    }


    /*
    |
    | initDefaults
    |---------------
    */
    initDefaults() {
        return {
            selectors: {
                'player': '[data-player]',
                'bouton': '[data-btn]',
                'cover':  '[data-img-cover]',
                'modal':  '[data-modal]',
            },
            controls: {
                'muted':      false,
                'volume':     0.7,
                'autoplay':   false,
                'loop':       {active:false},
                'fullscreen': {active:false},
                'autopause':  false,
                'customTime': false
            },
            options: {
                'cover': false,
                'popup': true,
                'videoAspect': false
            },
            coverParams: {
                'videobg': false,
                'videobg-width': false,
                'videobg-aspect': false,
                'videobg-maker-height': false,
                'videobg-hide-controls': false,
            }
        };
    }


    /**
    |
    | initParams
    |-------------
    */
    initParams(params) {
        let paramsObj = {
            ...this.defaults
        };
        const {
            selectors,
            controls,
            options,
            coverParams
        } = params;

        if (this.isDefined(selectors)) {
            paramsObj = {
                ...paramsObj,
                selectors
            };
        }

        if (this.isDefined(controls)) {
            paramsObj.controls = {
                ...paramsObj.controls,
                ...controls
            };
        }

        if (this.isDefined(options)) {
            paramsObj.options = {
                ...paramsObj.options,
                ...options
            };
        }
        if (this.isDefined(coverParams)) {
            paramsObj.coverParams = {
                ...paramsObj.coverParams,
                ...options
            };
        }

        return paramsObj;
    }

    /**
    |
    | Init
    |--------------------
    |
    */
    init() {

        if (this.playerWrapperExist()) {


            if (this.params.options.popup && this.modalWrapperExist()) {
                this.iniWithModal();
            }else if(this.params.options.cover){
                this.createrWrapperCover();
                this.initTimelineVideo();
            }

            if(this.params.controls.customTime){
                this.createrWrapperCustom();
            }

            if (this.videoWrapperExist()) {
               this.initPlayer();
            }

            

            if(this.params.options.cover){
                this.initFullVideo();
            }

        }

        
    }


    /*
    |
    | Creation de l'animation
    |--------------
    */
    initTimelineVideo(){

        this.timelineVideo
            .fromTo($(this.wrapper).find(this.params.selectors.cover)  ,0.5,{autoAlpha:1},{autoAlpha: 0} )
            .fromTo($(this.wrapper).find(this.params.selectors.btn)    ,0.5,{autoAlpha:1},{autoAlpha: 0} )
        ;

    }


    /**
    |
    | Init with modal
    |--------------------
    |
    */
    iniWithModal() {
        const _this = this;

        this.wrapperModal.iziModal({
            history: false,
            width: "70%",
            fullscreen: true,
            overlayColor: 'rgba(0, 0, 0, 0.7)',
            headerColor: '#000000',
            onOpening: function(){
                console.log(_this.wrapperPlayer);
                _this._player.play();
            },
            onClosing: function(){
                _this._player.pause();
            },
        });

        this.wrapperPlayer = this.wrapperModal.find(this.defaults.selectors.player);

    }



    /**
    |
    | Init
    |--------------------
    |
    */
    initPlayer(){
        const _this = this;

        this._player = new Plyr(this.wrapperPlayer);

        this.paramsPlayer();

        this._player.on('ready',function(){
            if (_this.params.controls.autoplay) {
                _this.timelineVideo.play();
                _this._player.play();
            }     
        });

        this._player.on('ended',function(){
            if (_this.wrapperModal.length > 0) {
                _this.wrapperModal.iziModal('close');
            }
        });

        if(this.params.controls.customTime){
            let tmp = 0

            this._player.on('timeupdate',function(e){
               let tmp = _this._player.currentTime * 100 / _this._player.duration ;
               TweenMax.to(_this.params.controls.customTimeMove,0.01,{width: tmp + '%'});
            });
        }

    }
    
    paramsPlayer(){

        if (this.params.controls.muted == true || this.params.options.cover == true ) {
            this._player.muted = true;
            this._player.volume = 0;
        }

        if (this.params.controls.repeat == true ) {
            //this._player.loop.active = true;
            this._player.config.loop.active = true
            //console.log(this._player.config.active = true);
        }
    }



     /**
    }
    }
    |
    | Player ready
    |--------------------
    |
    */
    playerautoplay(){


    }


    /**
    |
    | handleBtn
    |-------------------
    */
    handleBtn(){
        const $menuButton      = this.menuButton;
        $menuButton.on('click', () => {            
            if (this.menuIsActive()){
                this.close();
            } else {
                this.open();
            }
        });
    } 

    /**
    |
    | play
    |-----------------
    */
    play(){
        this.dispachEvent(this.wrapper, 'video:play');
    }

    /**
    |
    | pause
    |------------------------
    */
    pause(){
        this.dispachEvent(this.wrapper, 'video:pause');
    }

    /**
    |
    | open
    |-------
    */
    end(){
        this.dispachEvent(this.wrapper, 'video:end');
    }

    /**
    |
    | player found
    |-----------------------
    */
    playerWrapperExist() {
        this.wrapperPlayer = $(this.wrapper).find(this.defaults.selectors.player);
        return this.control(this.exist(this.wrapperPlayer), this.getMessage('playerWraperExist'));
    }
    
   
    /**
    |
    | Wrapper
    |-----------------------
    */
    videoWrapperExist() {
        return this.control(this.isDefined(this.wrapper), this.getMessage('videoWraperExist'));
    }


    /**
    |
    | Wrapper
    |-----------------------
    */
    modalWrapperExist() {
        this.wrapperModal = $(this.wrapper).find(this.defaults.selectors.modal);
        return this.control(this.isDefined(this.wrapperModal), this.getMessage('modalWraperExist'));
    }


    /**
    |
    | Créé des div 
    |-----------------------
    */
    createrWrapperCover(){

        var _this = this,
            eltCover = this.params.coverParams,
            _currentWrapper = _this.wrapper
        ;

        for (const [key, value] of Object.entries(eltCover)) {
            let _class = key;
            var myDiv = document.createElement("div");
            myDiv.setAttribute("class", _class);
            _currentWrapper.append(myDiv);
            _currentWrapper = myDiv;
            this.params.coverParams[key] = $(myDiv) ;

        }
        // On depkace la video 
        _currentWrapper.append(this.wrapperPlayer[0]);
    }




    createrWrapperCustom(){
        var _this = this,
            _currentWrapper = _this.wrapper
        ;

        var myDiv = document.createElement("div");
        myDiv.setAttribute("class", 'video-progress-ctr');
        _this.wrapper.append(myDiv);
        this.params.controls.customTime = $(myDiv);

         var myDiv = document.createElement("div");
        myDiv.setAttribute("class", 'video-progress');
        _this.params.controls.customTime.append(myDiv);
        this.params.controls.customTimeMove = $(myDiv);

    }
    /**
    |
    | Helper: exist
    |----------------
    */
    exist($item) {
        return $item.length;
    }


    /**
    |
    | Helper: control
    |------------------
    */
    control(condition, message, selector = null) {
        if (!condition) {
            if (selector === null) {
                console.error(message);
            } else {
                console.error(message, selector);
            }
        }

        return condition;
    }


    /*
    |
    | Active la video en background
    |--------------
    */
    videobgEnlarge(){
        let outerHeight = this.params.coverParams['videobg'].height(),
            outerWidth  = this.params.coverParams['videobg'].width(),
            windowAspect = outerHeight / outerWidth 
        ;

        //console.log(windowAspect);
        if (windowAspect > this.params.options.videoAspect ) {
            this.params.coverParams['videobg-width'].width((windowAspect / this.params.options.videoAspect) * 100 + '%');
        } else {
            this.params.coverParams['videobg-width'].width(100 + "%")
        }
    }

    initFullVideo(){
        let self = this,
            outerHeight = this.params.coverParams['videobg-aspect'].outerHeight(),
            outerWidth = this.params.coverParams['videobg-aspect'].outerWidth(),
            timeoutId
        ;

        this.params.options.videoAspect  = outerHeight / outerWidth;

        this.videobgEnlarge();

        $(window).resize(function() {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(function(){
                self.videobgEnlarge();
            }, 100);
        });
    }

    /**
    |
    | Helper: isDefined
    |--------------------
    |
    */
    isDefined(item) {
        return typeof item !== 'undefined';
    }

    /**
    |
    | Helper: getMessage
    |---------------------
    */
    getMessage(messageKey, var1 = '', var2 = '') {
        var messages = {
            'playerWraperExist' : 'The player not find -> [data-player]',
            'videoWraperExist': 'The form wrapper (specified as 1st parameter) does not exist:',
            'modalWraperExist':  'The Modal not find -> [data-modal]',
        };

        return 'Video : ' + messages[messageKey];
    }


}

export default Video;
