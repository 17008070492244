/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        console.log('doc.js');
        /*
        |
        | Constants
        |------------
        */
        const   $form               = $('.form-filter'),
                $select             = $('.select-filter'),
                $btnloadPost        = $(".btn-load-more-doc"),
                $ctrLoader          = $('.container-loader-btn'),
                $addresult          = $('#addresult'),
                cpt                 = $addresult.data('of-doc'),
                lang = $('body').data('lang') ? $('body').data('lang') : false
        ;


        $select.on('change', function (elt) {
            var data = $form.submit();
        });

        $btnloadPost.on('click',function (e) {
            e.preventDefault(); 
            let data = $form.length > 0 ? $form.serialize() : null;
            ajaxLoad(cpt,data,$(this).attr('next-link') );

        });

        let xhr = null;

        /*
        |
        | Load more ajax
        |--------------
        */
        function ajaxLoad(cpt,data,paged){

            abort(xhr);
            console.log(data);
            let url = `/ajax/docs/${cpt}/${data}/${paged}`;

            console.log(url);
            xhr = $.ajax({
                url: url,
                type: 'GET',
                method: 'GET',
                dataType: 'json',
                beforeSend: () => {
                    console.log('before send');
                     $ctrLoader.addClass('is-loading');
                },
                success: (response, status) => {
                    $ctrLoader.removeClass('is-loading');
                    $(response).appendTo($addresult);
                    let $nextDiv = $addresult.find('.change-nav');
                    if ($nextDiv.length > 0) {
                        $btnloadPost.removeClass('d-none').attr('next-link',$nextDiv.data('next'));
                        $nextDiv.remove();
                    }else{
                        $ctrLoader.addClass('is-finish');
                        $btnloadPost.addClass('no-more');
                    }
                    
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(status);
                    console.log(error);
                    console.log(response.responseText);
                }
            });
            
        }

        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null){
            if (xhrVar !== null) {
                xhrVar.abort();
            }

            if($list !== null){
                $list.find('.loading--ajax').hide();
            }
        }
    }
}
