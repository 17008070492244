/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, imagesLoaded) => {
        console.log('news.js');
        /*
        |
        | Constants
        |------------
        */
        const   $btnloadPost        = $(".btn-load-more-doc"),
                $addresult          = $('#addresult'),
                $ctrLoader          = $('.container-loader-btn'),
                cpt                 = $addresult.data('of-doc'),
                lang = $('body').data('lang') ? $('body').data('lang') : false
        ;


        $btnloadPost.on('click',function (e) {
            e.preventDefault(); 
            ajaxLoad(cpt,$(this).attr('next-link') );

        });

        let xhr = null;

        /*
        |
        | Load more ajax
        |--------------
        */
        function ajaxLoad(cpt,paged){

            abort(xhr);
            let url = `/ajax/news/${cpt}/${paged}`;

            console.log(url);
            xhr = $.ajax({
                url: url,
                type: 'GET',
                method: 'GET',
                dataType: 'json',
                beforeSend: () => {
                    console.log('before send');
                    // $postsContainer.fadeOut(200, function () {
                    //     $(this).html('')
                    // })
                    // $loadMoreContainer.show()
                    // $loadingContainer.show()
                     $ctrLoader.addClass('is-loading');
                },
                success: (response, status) => {
                    //console.log(response);
                    // $loadingContainer.hide()

                    // $postsContainer.fadeIn(200, function () {
                    //     $(this).html(response).show();
                    //     TweenMax.to($(this).find('[data-stagger-item]'), 0.5, {autoAlpha: 1});
                    // });
                     $ctrLoader.removeClass('is-loading');

                    $(response).appendTo($addresult);
                    let $nextDiv = $addresult.find('.change-nav');
                    if ($nextDiv.length > 0) {
                        $btnloadPost.removeClass('d-none').attr('next-link',$nextDiv.data('next'));
                        $nextDiv.remove();
                    }else{
                        $ctrLoader.addClass('is-finish');
                        $btnloadPost.addClass('no-more');
                    }
                    
                    let $img              = $('.lazy-ctr:not(.is-load) .img-lazy');
                    const preloadImages = new Promise((resolve, reject) => {
            //            imagesLoaded(document.querySelectorAll(".img-lazy"), { background: true }, resolve);
                        var imgLoad = imagesLoaded($img, { background: false }, resolve);
                        imgLoad.on( 'progress', function( instance, image ) {
                            var result = image.isLoaded ? 'loaded' : 'broken';
                            console.log( 'image is ' + result + ' for ' + image.img.src );
                            var lazyContainer = image.img.closest(".lazy-ctr");
                            lazyContainer.classList.add("is-load");
                        });
                    });

                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(status);
                    console.log(error);
                    console.log(response.responseText);
                }
            });
            
        }



        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null){
            if (xhrVar !== null) {
                xhrVar.abort();
            }

            if($list !== null){
                $list.find('.loading--ajax').hide();
            }
        }
    }
}
