/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {
        console.log('about.js');
        /*
        |
        | Constants
        |------------
        */
        var   $section                  = $(".section-team"),
                $allfilter              = $('.filter_fiche.all'),
                _defaultValue           = $allfilter.data('value'),
                _current                = [_defaultValue],
                $filters                = $('.filter_fiche.others')
        ;


        if ($section.length > 0) {
            var teamSwiper = new Swiper($section.find('.swiper-team'), {
                slidesPerView: 4,
                spaceBetween: 30,
                slidesPerColumnFill: 'row',
                navigation: {
                    nextEl: $section.find('.swiper-btn-next'),
                    prevEl: $section.find('.swiper-btn-prev')
                },
                scrollbar: {
                    el: $section.find('.swiper-scrollbar')
                },
                  breakpoints: {
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 0
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 30
                    },
                    992: {
                      slidesPerView: 4,
                      spaceBetween: 30
                    }
                  }
            } );

        }
    }
}
