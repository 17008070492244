/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {
        console.log('agenda.js');
        /*
        |
        | Constants
        |------------
        */
        var   $section                = $(".listing-agenda"),
                $allfilter              = $('.filter_fiche.all'),
                _defaultValue           = $allfilter.data('value'),
                _current                = [_defaultValue],
                $filters                = $('.filter_fiche.others')
        ;

        var  _options = {
            slidesPerView: 2,
            slidesPerColumn: 5,
            spaceBetween: 30,
            slidesPerColumnFill: 'row',
            navigation: {
                nextEl: $section.find('.swiper-btn-next'),
                prevEl: $section.find('.swiper-btn-prev')
            },
            scrollbar: {
                el: $section.find('.swiper-scrollbar')
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerColumnFill: 'column',
                   slidesPerView: 1,
                    slidesPerColumn: 5,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 2,
                    slidesPerColumn: 5,
                }
              }
        };

        var productSwiper = new Swiper('.swiper-agenda', _options );

        console.log(_current);


        $allfilter.on('click',function (e) {
            e.preventDefault();
            let _check_value_all = jQuery.inArray(_defaultValue ,_current);
            if(_check_value_all == -1 ){
                _current = [_defaultValue];
               //_current.splice( _check_value_all, 1 );
               $filters.removeClass('is-active');
               $allfilter.addClass('is-active');
                change_slider();
            }


        });


        $filters.on('click',function (e) {
            e.preventDefault();
            let _newvalue = $(this).data('value');
            let _check_value = jQuery.inArray(_newvalue ,_current);

            /*
            #
            #   Single value`
            # ------------------
            */
            _current = [_newvalue];
            $allfilter.removeClass('is-active');
            $filters.removeClass('is-active');
            $(this).addClass('is-active');

            /*
            #
            #   Multi value`
            # ------------------
            */
            /*`
            if ( _check_value == -1 ){
                _current.push(_newvalue);
                $(this).addClass('is-active');
            }else{
                _current.splice(_check_value, 1);
                $(this).removeClass('is-active');
            }

            let _check_value_all = jQuery.inArray(_defaultValue ,_current);
            if(_check_value_all != -1 && _current.length > 1 ){
               _current.splice( _check_value_all, 1 );
               $allfilter.removeClass('is-active');
            }else if( _check_value_all == -1 && _current.length == 0 ){
                _current.push(_defaultValue);
               $allfilter.addClass('is-active');
            }
            */
    
            change_slider();
        });



        function change_slider(){

            let _selecteur = '';
            for (var i = _current.length - 1; i >= 0; i--) {
                _selecteur = _selecteur +'.'+_current[i];
            }
          

            $section.find(".event-all").addClass("swiper-slide").removeClass("non-swiper-slide");
            $section.find(".swiper-slide").not(_selecteur).addClass("non-swiper-slide").removeClass("swiper-slide");

            productSwiper.destroy();
            productSwiper = new Swiper('.swiper-agenda', _options );

        }   


        
        
    }
}
