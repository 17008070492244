/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        console.log('hebdo.js');
        /*
        |
        | Constants
        |------------
        */
        const   $btnloadPost        = $(".btn-load-more-doc"),
                $btnContainer       = $('.btn-container'),
                $addresult          = $('#addresult'),
                cpt                 = $addresult.data('of-doc'),
                lang                = $('body').data('lang') ? $('body').data('lang') : false
        ;


        $btnloadPost.on('click',function (e) {
            e.preventDefault(); 
            ajaxLoad($(this).attr('next-link'));

        });

        let xhr = null;

        /*
        |
        | Load more ajax
        |--------------
        */
        function ajaxLoad(paged){
            // console.log(paged);

            abort(xhr);
            let url = `/ajax/hebdo/${paged}`;

            // console.log(url);
            xhr = $.ajax({
                url: url,
                type: 'GET',
                method: 'GET',
                dataType: 'json',
                beforeSend: () => {
                    // console.log('before send');
                    // $postsContainer.fadeOut(200, function () {
                    //     $(this).html('')
                    // })
                    // $loadMoreContainer.show()
                    // $loadingContainer.show()
                     $btnloadPost.addClass('d-none');
                     $btnContainer.addClass('loading');
                },
                success: (response, status) => {
                    // $loadingContainer.hide()

                    // console.log(status);
                    // console.log(response);
                    // console.log(response.responseText);

                    // $postsContainer.fadeIn(200, function () {
                    //     $(this).html(response).show();
                    //     TweenMax.to($(this).find('[data-stagger-item]'), 0.5, {autoAlpha: 1});
                    // });

                    $(response).appendTo($addresult);
                    let $nextDiv = $addresult.find('.change-nav');
                    if ($nextDiv.length > 0) {
                        $btnloadPost.removeClass('d-none').attr('next-link',$nextDiv.data('next'));
                        $btnContainer.removeClass('loading');
                        $nextDiv.remove();
                    }else{
                        $btnloadPost.addClass('no-more');
                        $btnContainer.addClass('loading');
                    }
                    
                    xhr = null;
                },
                error: (response, status, error) => {
                    // console.log(status);
                    // console.log(error);
                    // console.log(response.responseText);
                    // console.log(response);
                }
            });
            
        }

        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null){
            if (xhrVar !== null) {
                xhrVar.abort();
            }

            if($list !== null){
                $list.find('.loading--ajax').hide();
            }
        }
    }
}
