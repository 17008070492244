/*** IMPORTS FROM imports-loader ***/
var define = false;

import gsap from 'gsap';
/*
|
| Class
|--------
|
*/
class Menu
{
    /*
    |
    | Constructor
    |--------------
    */
    constructor($menuWrapper, $menuButton, $menuClose ,params = {}) {
		this.bindMethods();

        this.menuWrapper  = $menuWrapper;
		this.menuButton   = $menuButton;
		this.tabPanel     = $menuWrapper.find('.menu-item.menu-item-has-children');
		this.menuClose    = $menuClose;
		this.currentMobilPanel = false; 
		this.menuTimeline = this.initTimeline();
		this.params 	  = this.initParams(params);
		this.menuIsActive = false;
	}

	/*
	|
	| bindMethods
	|--------------
	*/
	bindMethods(){
		this.handleComplete        = this.handleComplete.bind(this);
		this.handleReverseComplete = this.handleReverseComplete.bind(this);
	}
	
	/*
	|
	| initParams
	|--------------
	*/
	initParams(params){
		const { activeClass, reverseTimeScale } = params;

		return {
			'activeClass'     : this.isDefined(activeClass) 	 ? activeClass      : 'menu-active',
			'reverseTimeScale': this.isDefined(reverseTimeScale) ? reverseTimeScale : 1
		}
	}

	initTab(){

		let self = this ; 
		this.tabPanel.find('.item-link .icon').on('click',function (e) {
			e.preventDefault();
			console.log($(window).width());
			if ($(window).width() <= 991 ) {
				let $parent = $(this).parents('.menu-item'); 
				$parent.toggleClass('is-active');
			}else{
				location.href(e.target);
			}
		});

	}
	/*
	|
	| initTimeline
	|---------------
	*/
	initTimeline(){
		return gsap.timeline({ 
			paused: true,
			onComplete: this.handleComplete,
			onReverseComplete: this.handleReverseComplete
		})
	}

    /**
	|
	| Init
	|-------
    */
    init(){
        if (this.menuWrapperExist() && this.menuButtonExist()) {
			this.handleToggleMenu();
        }
        if (this.tabPanelExist() >= 1) {
			this.initTab();
        }
	}
	
	/**
    |
    | handleToggleMenu
    |-------------------
    */
	handleToggleMenu(){
		const $menuButton      = this.menuButton;
		const $menuClose       = this.menuClose;
		const menuTimeline     = this.menuTimeline;
		const reverseTimeScale = this.params.reverseTimeScale;

		$menuButton.on('click', () => {            
			if (this.menuIsActive){
                this.close();
                $('body').removeClass('overflowhidden');
                this.menuIsActive = false;
			} else {
                this.open();
                $('body').addClass('overflowhidden');
                this.menuIsActive = true;
            }
        });

        $menuClose.on('click', () => { 
			if (this.menuIsActive){
                this.close();
                $('body').removeClass('overflowhidden');
                this.menuIsActive = false;
			} else {
                this.open();
                $('body').addClass('overflowhidden');
                this.menuIsActive = true;
            }
        });
	} 

	/**
	|
	| handleComplete
	|-----------------
	*/
	handleComplete(){
		this.dispachEvent(this.menuWrapper, 'menu:opened');
	}

	/**
	|
	| handleReverseComplete
	|------------------------
	*/
	handleReverseComplete(){
		this.dispachEvent(this.menuWrapper, 'menu:closed');
	}

    /**
	|
	| open
	|-------
	*/
    open(){
    	console.log(this.menuTimeline);
        this.menuTimeline.timeScale(1).play();
        this.dispachEvent(this.menuWrapper, 'menu:open');
    }

    /**
	|
	| close
	|-------
	*/
    close() {
    	console.log(this.menuTimeline);
        this.menuTimeline.timeScale(2).reverse();
        this.dispachEvent(this.menuWrapper, 'menu:close');
    }

	/**
	|
	| menuIsActive
	|---------------
	menuIsActive(){
        return this.menuTimeline.totalProgress() !== 0;
	}
	*/
	tabPanelExist(){
        return this.control(this.exist(this.tabPanel), this.getMessage('SubMenuExist'));
	}
    /**
    |
    | menuWrapperExist
    |-------------------
    */
    menuWrapperExist(){
        return this.control(this.exist(this.menuWrapper), this.getMessage('menuWrapperExist'));
	}
	
	/**
    |
    | menuButtonExist
    |------------------
    */
   	menuButtonExist() {
        return this.control(this.exist(this.menuButton), this.getMessage('menuButtonExist'));
    }


    /**
	|
	| Helper: isDefined
	|--------------------
	|
	*/
	isDefined(item){
		return typeof item !== 'undefined';
	}


    /**
	|
	| Helper: exist
	|----------------
	*/
	exist($item){
		return $item.length;
	}

	
	/**
	|
	| Helper: control
	|------------------
	*/
	control(condition, message, selector = null){
		if(!condition){
			if(selector === null){
				console.error(message);
			} else {
				console.error(message, selector);
			}
		}

		return condition;
	}

	
	/**
	|
	| Helper: dispachEvent
	|-----------------------
	*/
	dispachEvent($element, eventName, datas = null){
		var event = $.Event(eventName);

		if(datas !== null){
			$.each(datas, function(key, value){
				event[key] = value
			});
		}

		$element.trigger(event);
	}

    /**
	|
	| Helper: getMessage
	|---------------------
	*/
	getMessage(messageKey, var1 = '', var2 = ''){
		var messages = {
			'menuWrapperExist': 'The menu wrapper (specified as 1st parameter) does not exist',
			'menuButtonExist' : 'The button menu (specified as 2nd parameter) does not exist',
		};

		return 'Menu: ' + messages[messageKey];
	}
}

export default Menu;
