/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Video, printThis, Collapse) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $formDefault = $('.block-form').find('.gform_wrapper'),
            $printBtn = $('.print-btn')
            ;
        /*
       |
       |  Print Key 
       |---------------------
       |*/
        if ($printBtn.length >= 1) {
            $printBtn.on('click', function (e) {
                e.preventDefault();
                //$('body').addClass('print-key');
                // window.print();
                $(this).parents('.print-section').printThis({
                    importCSS: true,
                });

            });

        }
        /*
       |
       |  Form 
       |---------------------
       |*/
        if ($formDefault.length >= 1) {
            $formDefault.each(function () {
                app.init_form_label($(this).find('form'));
            });
        }


        $(document).ready(function () {
            jQuery('form .gfield_label').addClass('active');
        });


        /*
        |
        | Search
        |------------
        */
        $('#active-search').on('click', function (e) {
            e.preventDefault();
            $($(this).attr('href')).toggleClass('is-active');
        });


        /*
        |
        | Video Popu
        |------------
        */
        const
            $ficheVideoPopup = $('[data-item-video-popup]')
            ;

        if ($ficheVideoPopup.length >= 1) {
            $ficheVideoPopup.each(function () {
                var $self = $(this);
                var option = {
                    options: {
                        popup: true
                    }
                };
                var video = new Video($self, option);

            });
        }




        $body.on('loaderEnd', () => console.log('ended'))
    }
}
