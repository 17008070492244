/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app) => {
		app.dump('form.js');

		/*
		|
		| Constants
		|-----------
		*/
		const 
			$formDocs = $('.gform_body')
		;

		if ($formDocs.length > 0) {
			const $gfield_list_group 	= $formDocs.find('.g-table-col .gfield_list_group'),
			    $Inputnumbers 		= $formDocs.find('.g-table-col .number'),
				$inputHT 		= $formDocs.find('.input-ht input'),
				$inputTTC 		= $formDocs.find('.input-ttc input'),
				$montantHT 			= $formDocs.find('#montant-ht .nb'),
				$montantTTC 		= $formDocs.find('#montant-ttc .nb');

			var	_totalHT 			= 0,
				_totalTTC			= 0
			;

			console.log($inputHT);
			console.log($inputTTC);

			$Inputnumbers.on('change',function (index) {
				changeMontant($gfield_list_group, $inputHT, $inputTTC, $montantHT, $montantTTC);
			});

				changeMontant($gfield_list_group, $inputHT, $inputTTC, $montantHT, $montantTTC);
			
			$(document).on('gform_confirmation_loaded', function(event, formId){
	            if(formId == 1) {
	                // run code specific to form ID 1
	            } else if(formId == 2) {
	                // run code specific to form ID 2
	            }
	            console.log(formId);
	        });
		}

		function changeMontant( $gfield_list_group, $inputHT , $inputTTC, $montantHT, $montantTTC){
			var _totalHT = 0;
			var _totalTTC = 0;

			$gfield_list_group.each(function(index) {

				let $number = $(this).find('.number'),
					$namefile = $(this).find('.namefile'),
					$price = $(this).find('.price')
				;

				_totalHT = _totalHT + ( $price.val() * $number.val() );
				_totalTTC = _totalHT + (_totalHT * 20/100 ) ;

			});

			$montantHT.html(parseFloat(_totalHT).toFixed(2));
			$montantTTC.html(parseFloat(_totalTTC).toFixed(2));
			$inputHT.val(parseFloat(_totalHT).toFixed(2));
			$inputTTC.val(parseFloat(_totalTTC).toFixed(2));
		}
	}
}

