/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap, ScrollTrigger, Menu, Kira, CustomEase, imagesLoaded) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            html = document.querySelector('html'),
            body = document.querySelector('body'),
            $body = $('body'),
            $pageLoader = $('.page-loader'),
            scrollAreas = document.querySelectorAll('.scroll-area'),
            $splitItems = $body.find('[data-splittext]'),
            $img = $('.img-lazy')

            ;



        /*
         |
         | PreloadImage
         |----------
         */
        const preloadImages = new Promise((resolve, reject) => {
            //            imagesLoaded(document.querySelectorAll(".img-lazy"), { background: true }, resolve);
            var imgLoad = imagesLoaded($img, { background: false }, resolve);

            imgLoad.on('progress', function (instance, image) {
                var result = image.isLoaded ? 'loaded' : 'broken';
                console.log('image is ' + result + ' for ' + image.img.src);
                var lazyContainer = image.img.closest(".lazy-ctr");
                lazyContainer.classList.add("is-load");
            });

        });

        /*
        |
        | Easings
        |----------
        */
        CustomEase.create("easeCustom", "M0,0 C0.4,0 0.2,1 1,1")
        CustomEase.create("easeSmooth", "M0,0 C0.19,1 0.22,1 1,1")
        CustomEase.create("easeCustomPower1", "M0,0 C0.165,0.84 0.44,1 1,1")
        CustomEase.create("easeExpo", "M0, 0 C1, 0 0, 1 1, 1")

        /*
        |
        | SplitText
        |------------
        */
        $.each($splitItems, function () {
            const $item = $(this);
            const type = $item.data('splittext') ? $item.data('splittext') : 'words, chars';
            const split = new SplitText($item, { type: type, linesClass: 'item-line', wordsClass: 'item-word', charsClass: 'item-char' });

            $item[0]['split'] = split;
        });

        $body.on('dom:ready', () => {
            $splitItems.addClass('split-ready')
        })


        /*
        |
        | Scroll Areas
        |---------------
        */
        Array.prototype.forEach.call(scrollAreas, element => {
            Scrollbar.init(element);
        });


        /*
        |
        | Loader
        |---------
        */
        if (sessionStorage.getItem('loaded_once') === null) {
            sessionStorage.setItem('loaded_once', 'loaded_once');
        }

        if ($pageLoader.hasClass('active')) {
            const loaderTl = gsap.timeline({ paused: true, /*onComplete: () => $pageLoader.remove()*/ });

            loaderTl.to($pageLoader.find('.item-loadbar-inner'), 0.4, { scaleX: 1, ease: 'Power0.easeNone' }, 'start')
            loaderTl.to($pageLoader.find('.item-content'), 0.8, { autoAlpha: 0, ease: 'Power1.easeOut' }, '-=0')
            loaderTl.call(() => { app.dispachEvent(body, 'dom:ready'); }, null)
            loaderTl.to($pageLoader, 0.8, { autoAlpha: 0, ease: 'Power1.easeOut' }, '-=0')

            $(window).on('load', function () {
                loaderTl.play();

            });
        } else {
            $(window).on('load', function () {
                app.dispachEvent(body, 'dom:ready');
            })

        }


        /*
        |
        | Menu
        |-------
        */
        const $header = $('#header'),
            $menuOpen = $header.find('.btn-menu'),
            $menu = $('#header-fullscreen'),
            $menuClose = $menu.find('[data-close]'),
            $menuStaggerItems = $menu.find('[anim-item]'),
            $menuBg = $menu.find('.header-bg')
            ;


        const menu = new Menu($menu, $menuOpen, $menuClose, {
            reverseTimeScale: 2
        });
        menu.menuTimeline
            .fromTo($menu, 0.1, { autoAlpha: 0 }, { autoAlpha: 1, ease: 'Power1.easeOut' })
            .fromTo($menuBg, 0.4, { x: '-100%' }, { x: '0%', ease: 'Power1.easeOut' }, 0)
            .fromTo($menuStaggerItems, 0.1, { autoAlpha: 0 }, { autoAlpha: 1, ease: 'Power1.easeOut' })
            // .staggerFrom($menuStaggerItems, 0.4, { autoAlpha: 0, x: "20%", ease: 'Power1.easeOut' }, '0.1', '+=0.3')
            ;

        menu.init();

        $menu.on('menu:open', () => {
            $menu.toggleClass('is-menu');
            $header.toggleClass('is-menu');
            //Config.$body.toggleClass('--no-scroll');
        });

        $menu.on('menu:closed', () => {
            $menu.toggleClass('is-menu');
            $header.toggleClass('is-menu');
            //Config.$body.toggleClass('--no-scroll');
        });

        /*
        |
        | Kira
        |-----------
        */
        const kira = new Kira({
            loadEvent: [body, 'dom:ready'],
            scrollTrigger: {
                markers: false,
                //scroller: scrollContainerSelector,
            },
            tweenParams: {
                start: '-=0.6'
            }
        });

        /*
        |
        | Header on Scroll
        |
        */
        const showAnim = gsap.from($header, {
            yPercent: -100,
            paused: true,
            duration: 0.2
        }).progress(1)

        ScrollTrigger.matchMedia({
            "(min-width: 992px)": function () {
                ScrollTrigger.create({
                    start: 'top -200',
                    end: 99999,
                    onUpdate: (self) => {
                        self.direction === -1 ? showAnim.play() : showAnim.reverse()
                    },
                    toggleClass: { className: 'is-scrolled', targets: $header }
                });

            },
            "(max-width: 991px)": function () {
                showAnim.play()
            }
        })

        /*
        | fadeInUp
        |-----------
        */
        kira.add('fadeInUp', (item, timeline, start) => {
            timeline.fromTo(item, 1.3, { y: 50 }, { y: 0, autoAlpha: 1, ease: 'easeSmooth' }, start)
        });

        /*
        | fadeInUp.parallax.sm
        |-----------------------
        */
        kira.add('fadeInUp.parallax', (item, timeline, start) => {
            timeline.fromTo(item, 0.8, { y: 100 }, { y: 0, autoAlpha: 1, ease: 'Sine.easeOut' }, start)
        });

        /*
        | splitline
        |------------
        */
        kira.add('splitline', (item, timeline, start) => {
            const delay = item.dataset.delay ? item.dataset.delay : 0.012;

            item.querySelectorAll('.item-line').forEach(line => {
                timeline.from($(line).find('> div, > em, > span'), 1.35, { y: '101%', ease: 'easeSmooth' }, start)
            })
        });

        /*
        | splittext.long
        |-----------------
        */
        kira.add('splittext.long', (item, timeline, start) => {
            const delay = item.dataset.delay ? item.dataset.delay : 0.01;

            timeline.staggerFrom(item.split.chars, 0.8, { y: 5, opacity: 0, ease: 'Sine.ease0ut' }, delay, start)
        });

        /*
        | fadeInLeft.parallax.sm
        |-------------------------
        */
        kira.add('fadeInLeft.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFromTo($item.querySelectorAll('[data-stagger-item]'), 0.6, { x: 20 }, { autoAlpha: 1, x: 0, ease: 'Power1.easeOut' }, '0.1', start)
        });

        /*
        | fadeInUp.parallax.sm
        |-------------------------
        */
        kira.add('fadeInUp.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFromTo($item.querySelectorAll('[data-stagger-item]'), 1, { x: 20 }, { opacity: 1, x: 0, ease: 'Power1.easeOut' }, '0.05', start)
        });

        kira.init();


        $body.on('loaderEnd', () => {
            console.log('loaderEnd');

            /*
            |
            | LazyLoad
            |-----------------
            */
            let allDone = [preloadImages];

            Promise.all(allDone).then(() => {
                console.log('Toutes les promises sont DONE');
            });


        });

    }
}
