/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import Collapse from 'bootstrap/js/dist/collapse.js';

import Swiper from 'swiper/js/swiper.min';
import gsap from "gsap";
import CustomEase from "@lib/gsap-pro/CustomEase";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import Scrollbar from 'smooth-scrollbar';

import imagesLoaded from 'imagesloaded';

import printThis from 'print-this';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollTo);

/*
|
| Importing Components
|-----------------------
*/
import customGoogleMap from '@components/custom-google-map.js';
import MaterializeForm from '@components/materialize-form.js';
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';
import Video from '@components/video.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import docs from '@pages/docs.js';
import news from '@pages/news.js';
import animations from '@pages/animations.js';
import sample from '@pages/sample.js';
import contact from '@pages/contact.js';
import agenda from '@pages/agenda.js';
import hebdo from '@pages/hebdo.js';
import about from '@pages/about.js';
import form from '@pages/form.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        'file': animations,
        'dependencies': [app, gsap, ScrollTrigger, Menu, Kira, CustomEase, imagesLoaded]
    },
	{
		'file': main, 
		'dependencies': [app, Video, printThis, Collapse]
    },
    {
		'file': docs, 
		'dependencies': [app],
		'resolve': '#page-listing-outils'
    },
    {
		'file': news, 
		'dependencies': [app, imagesLoaded],
		'resolve': '#page-news-archive'
    },
    {
		'file': agenda, 
		'dependencies': [app, Swiper],
		'resolve': '#page-agenda'
    },
    {
		'file': about, 
		'dependencies': [app,Swiper],
		'resolve': '#page-about'
    },
	{
		'file': sample, 
		'dependencies': [app],
		'resolve': '#page-sample'
    },
    {
		'file': form, 
		'dependencies': [app],
		'resolve': '#page-form'
    },
    {
		'file': hebdo, 
		'dependencies': [app],
		'resolve': '#page-hebdo'
    },
    {
        'file': contact,
        'dependencies': [app, MaterializeForm, customGoogleMap],
        'resolve': '#page-contact'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

